import gsap from "gsap";

const grid = document.querySelector('.grid-2')
const items = document.querySelectorAll('.item')

items.forEach((item) => {
  item.addEventListener('mouseenter', () => {
    gsap.to(grid, {
      '--track': '2fr',
      duration: 0.5,
    })
    gsap.to(item, {
      '--innerTrack': '1fr',
      duration: 0.5,
    })
  })

  item.addEventListener('mouseleave', () => {
    gsap.to(grid, {
      '--track': '1fr',
      duration: 0.5,
    })
    gsap.to(item, {
      '--innerTrack': '0fr',
      duration: 0.5,
    })
  })
})