const wrapper = document.querySelector(".tour");

var card = document.getElementsByClassName('card-xx');
var scrl = 0;
var n = 0;
var prev;
const actionMenu = document.querySelectorAll(".tour__menu-tour");
var data = ['tour1', 'tour2', 'tour3'];
var fullHeight = window.innerHeight;

for (var i = 0; i < card.length; i++) {
  card[i].style.zIndex = i;
}

function stickyDisplay(){
  scrl = window.pageYOffset;
  prev = n;
  n = Math.floor(scrl/fullHeight);


  window.addEventListener('resize', function(){
    scrl = window.pageYOffset;
    prev = n;
    n = Math.floor(scrl/fullHeight);
  })

  var c = card[n].clientHeight - fullHeight / 2;
  var x = Math.floor(scrl/c);

  if(x > 1){
    c = card[n].clientHeight - fullHeight / 4;
    x = Math.floor(scrl/c);
  };
  
  if(x < 3){
    actionMenu.forEach((res) => {res.classList.remove('active')});
    actionMenu[x].classList.add("active");
  }

  if(n < 2){
    if (n > prev && n < card.length-1){
      card[n].style.position = 'fixed';
      card[n].style.top = '0px';
      card[n].style.marginTop = '0';
      card[n+1].style.marginTop = fullHeight*(n+1) +'px';
    }
    if (n < prev){
      card[n+1].style.position = 'relative';
      card[n+1].style.top = '0';
      card[n+1].style.marginTop = fullHeight*(n+1) +'px';
    }   
  } else return; 
}

var tourLinks = document.getElementsByClassName("tour__menu-tour");

for (var i = 0; i < tourLinks.length; i++) {
	tourLinks[i].addEventListener("click", function(event) {
		event.preventDefault(tourLinks[i]);
	
		var targetId = this.getAttribute("href");
		var targetSection = document.querySelector(targetId);
    var offsetTour = targetSection.offsetTop + window.innerHeight;
    
    if(targetId == "#tour1") {
      offsetTour = 0;
    }

		window.scrollTo({
			top: targetId == "#tour3" ? offsetTour + window.innerHeight : offsetTour,
			behavior: "smooth"
		});
	
		for (var j = 0; j < tourLinks.length; j++) {
		  tourLinks[j].classList.remove("active");
		}
		this.classList.add("active");
	});
};

window.addEventListener('resize', stickyDisplay);
window.addEventListener('scroll' , stickyDisplay);