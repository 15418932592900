import './components/banner.js';
import './components/tour.js';
import './components/faq.js';
// import './components/scrollHeader.js';
import './components/feature.js';
import './components/menu.js';

import './app.js';

import '../../node_modules/aos/dist/aos.js';
import './other/jquery.particleground.js';
import './other/owl.carousel.js';