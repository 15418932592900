setTimeout(() => {
	const contentBanner = document.querySelector(".wrap-banner__content");
	contentBanner.classList.add("animate__fadeOut")
}, 1500);

setTimeout(() => {
	const contentBanner = document.querySelector(".wrapper__banner");
	contentBanner.classList.add("animate__fadeOut");
	contentBanner.classList.add("d-none");
}, 1500);

setTimeout(() => {
	var item1 = [1, 3, 5],
		item2 = [2, 4, 6]
	item1.map((i) => {
		slideUp(i);
	})

	item2.map((i) => {
		slideDown(i);
	})
}, 1000);

function slideUp(nth){
	const grid = document.querySelectorAll(".wrap-banner__grid:nth-child(" + nth + ")");
	grid.forEach((item) => {
		item.classList.add("animate__slideOutUp")
	})
}

function slideDown(nth){
	const grid = document.querySelectorAll(".wrap-banner__grid:nth-child(" + nth + ")");
	grid.forEach((item) => {
		item.classList.add("animate__slideOutDown")
	})
}