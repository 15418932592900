var menuLinks = document.getElementsByClassName("menu__link");
var inputIcon = document.getElementsByClassName("menu-icon__cheeckbox");

function scrollToSection(targetId) {
  var targetSection = document.querySelector(targetId);
  var offsetTour = window.innerHeight * 3;
  var targetOffsetTop = targetSection.offsetTop;

  window.scrollTo({
    top: targetOffsetTop + offsetTour,
    behavior: "smooth"
  });

  window.addEventListener('resize', () => {
    var offsetTour = window.innerHeight * 3;
    var targetOffsetTop = targetSection.offsetTop;

    window.scrollTo({
      top: targetOffsetTop + offsetTour,
      behavior: "smooth"
    });
  })
}

// Attach click event listeners to each menu link
for (var i = 0; i < menuLinks.length; i++) {
  menuLinks[i].addEventListener("click", function(event) {
    event.preventDefault(menuLinks[i]);

    var targetId = this.getAttribute("href");

    inputIcon[0].checked = false;
    $(".wrapper__list-menu").toggle();
    $(".wrapper").toggleClass("show-menu");
    $(".card-xx").toggleClass("hidden-tour");
    $("body").toggleClass("hidden");
    $(".wrapper__main").toggleClass("content-show-menu");

    setTimeout(() => {
      scrollToSection(targetId);
    }, 500);

    for (var j = 0; j < menuLinks.length; j++) {
      menuLinks[j].classList.remove("active");
    }
    this.classList.add("active");
  });
};