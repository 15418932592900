var buttonAccordion = document.getElementsByClassName("accordion-button");

for (var i = 0; i < buttonAccordion.length; i++) {
    buttonAccordion[i].addEventListener('click', function(event){        
        event.preventDefault(buttonAccordion[i]);

        for (var j = 0; j < buttonAccordion.length; j++) {
            buttonAccordion[j].classList.remove("show");
        }
        this.classList.add("show");
    });
}