import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import AOS from 'aos';
window.jQuery = window.$ = $;

if (!window.site) window.site = {};

window.site.initPage = function () {
	window.scrollTo(0, 0);
	AOS.init();
	bannerLoadingContent();
	$(document).ready(function(){
		$(".menu-icon__cheeckbox").click(function(){
			$(".wrapper__list-menu").toggle();
			$(".wrapper").toggleClass("show-menu");
			$(".card-xx").toggleClass("hidden-tour");
			$("body").toggleClass("hidden");
			$(".wrapper__main").toggleClass("content-show-menu");
		});
	});
};

window.site.initElements = function () {
  	svg4everybody();
};

window.addEventListener('resize', () => {
	AOS.init();
});

window.addEventListener('load', () => {
	window.scrollTo(0, 0);
	window.site.initPage();
	window.site.initElements();
	$(document).ready(function() {
		$('#particles').particleground({
			dotColor: '#585858',
			lineColor: '#585858',
			parallax: false,
			directionY:'top',
			directionX:'center',
			density: 10000,
			particleRadius: 7
		});

		$('#particles2').particleground({
			dotColor: '#585858',
			lineColor: '#585858',
			parallax: false,
			directionY:'top',
			directionX:'center',
			density: 10000,
			particleRadius: 7
		});

		$('.intro').css({'margin-top': -($('.intro').height() / 2)
		});

		$(".testimonial__grid").owlCarousel({
			margin: 32,
    		nav: true,
			dots: true,
			responsive:{
				0:{
					items:1.2,
					margin: 20,
					loop: true,
					stagePadding: 20,
				},
				767:{
					items:2,
					margin: 20,
					nav: false
				},
				920:{
					nav: false,
					items:3,
				},
				1025:{
					nav: true,
					items:3,
				}
			}
		});

		$(".services__grid-owl").owlCarousel({
			margin: 20,
			stagePadding: 20,
    		nav: false,
			dots: false,
			items: 1.5,
			loop: true,
		});

		$(".feature__owl").owlCarousel({
			margin: 20,
			stagePadding: 20,
    		nav: false,
			dots: false,
			loop: true,
			items: 1.2,
		});
	});
});


function bannerLoadingContent() {
	var i = 0;
	if (i == 0) {
		i = 1;
		var elem = document.getElementById("myBar");
		var count = document.getElementById("countBar");
		var width = 1;
		var id = setInterval(frame, 5);
		function frame() {
		if (width >= 100) {
			clearInterval(id);
			i = 0;
		} else {
			width++;
			elem.style.width = width + "%";
			count.innerHTML = width  + "%";
		}
		}
	}
}